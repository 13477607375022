import gql from 'graphql-tag';

export const ExperimentalUpdateShortcuts = gql`
	mutation ExperimentalUpdateShortcuts(
		$spaceKey: String!
		$quickLinks: [ExperimentalSpaceSidebarLink]
	) @experimental {
		experimentalUpdateShortcutLinks(spaceKey: $spaceKey, quickLinks: $quickLinks) {
			quickLinks {
				id
				url
				position
				title
				styleClass
			}
			__typename
		}
	}
`;
